import React from 'react';


const NotFoundPage = () => {
  return (
    <>
    <h1>Not Found</h1>
    </>
  );
};

export default NotFoundPage;
